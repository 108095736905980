<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 mr-auto ml-auto">
        <wizard @on-complete="wizardComplete" @on-change="onChange">
          <template slot="header">
            <h3 class="card-title">Update Lead</h3>
            <h3 class="description">This information will let us know more about the new Lead.</h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">Step One</template>
            <first-step ref="step1" @on-validated="onStepValidated"></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">Step Two</template>
            <second-step ref="step2" @on-validated="wizardComplete"></second-step>
          </wizard-tab>
        </wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from "./WizardEdit/FirstStep.vue";
import SecondStep from "./WizardEdit/SecondStep.vue";
import swal from "sweetalert2";

import { Wizard, WizardTab } from "src/components/UIComponents";

export default {
  data() {
    return {
      wizardModel: {}
    };
  },
  components: {
    FirstStep,
    SecondStep,
    Wizard,
    WizardTab
  },
  mounted() {
    this.$store.dispatch("fetchLead", this.$route.params.id);
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete(validate, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
      if (typeof this.wizardModel.known_diseases == "object" && this.wizardModel.known_diseases != null) {
        this.wizardModel.known_diseases = this.wizardModel.known_diseases.join(
          ","
        );
      }

      if (validate) this.$store.dispatch("updateLead", this.wizardModel);
    },
    onChange(prevIndex, nextIndex) {
      console.log("prev", prevIndex, "next", nextIndex);
    }
  }
};
</script>
